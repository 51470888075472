/* ===========================
   Utilities
   =========================== */

/*
	Display
 */

.hidden {
    display: none !important;
}


/*
	Alignment
 */

%wrapper {
	max-width: $wrapper-width;
	width: 100%;
}


/*
	Classes
 */



