/* ===========================
   Variables
   =========================== */

// Color
$primary: rgb(0,162,52);
$primary-sw: rgb(0,162,52);
$dark: rgb(121,120,120);
$light: rgb(115,198,122);
$neutral: rgb(226,226,226);

$red: rgb(227,48,53);
$gray: rgb(70,70,70);
$gray-sw: rgb(59,59,59);
$lightgray-sw: #E2E2E2;
$gray-footer: #E8E8E8;

$white: rgb(255,255,255);
$black: rgb(0,0,0);

//Sizes
$wrapper-width: 1150px;
$wrapper-padding: 20px;
$nav-height: 9rem;

$general-margin: 3%;

// Media Queries
$phablet-width: 410px;
$tablet-width: 650px;
$laptop-width: 980px;
$desktop-width: 1200px;