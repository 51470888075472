/* ===========================
   Base
   =========================== */

/*
	Webfonts
 */




/*
	General
 */

.preload * {
	transition: none !important;
}

@viewport {
	width: device-width;
}

html {
	font-family: 'Work Sans', sans-serif;
}

body {
	font-variant-ligatures: no-common-ligatures;

	// Sticky footer
	display: flex;
  	min-height: 100vh;
  	flex-direction: column;
}

.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6,
h1, h2, h3, h4, h5, h6 {
	color: $gray;
	margin-top: 2rem;
	margin-bottom: 1rem;
	font-weight: bold;
	line-height: 1.4;
	letter-spacing: 1.5px;
	&:first-child {
		margin-top: 0;
	}
	word-wrap: break-word;
}

.heading-1,
h1 {
	font-size: 2rem;
}

.heading-2,
h2 {
	font-size: 1.0rem;
}

.heading-3,
h3 {
	font-size: 1.4rem;
}

.heading-4,
h4 {
	font-size: 0.8rem;
	font-weight: normal;
}

a {
	text-decoration: none;
	color: $primary;

	&:hover {
		color: rgba($primary, .7);
	}
}

.swisswindow a {
	color: $red;

	&:hover {
		color: rgba($red, .7);
	}
}

p {
	margin-bottom: 1rem;
	line-height: 1.65;
	font-size: 1rem;

	&:last-child {
		margin-bottom: 0;
	}
}

img {
	/*width: 100%;
	max-width: 100%;*/
}

strong {
	font-weight: 800;
}

ul {
	list-style: inside;
}




/**
 *  Formular
 */

button:not(.m-hamburger):not(.previous):not(.next):not(.fancybox-arrow),
input,
input[type="button"],
input[type="checkbox"],
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="radio"], 
input[type="search"],
input[type="submit"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
	width: 50%;
    font-family: 'Work Sans', sans-serif;
    margin: 5px;
    border: none;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.15);

    &:focus {
    	outline: none;
    }
}

input[type=submit] {
    width: 50%;
    background-color: $primary;
    color: $white;
    text-transform: uppercase;
}