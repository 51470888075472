/* =======================
   Reset
   ======================= */

@viewport {
	width: device-width;
}

*, *::before, *::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html, body {
	background-color: rgb(255, 255, 255);
}

html {
	overflow-y: scroll;
	font-size: 100%;
	line-height: 1.25;
	color: rgb(0, 0, 0);
	text-size-adjust: none;
	text-rendering: optimizeLegibility;
	//-webkit-font-smoothing: antialiased;
	//-moz-osx-font-smoothing: grayscale;
}

body {
	font-variant-ligatures: no-common-ligatures;
}

article,
aside,
header,
main,
footer,
section,
nav,
figure,
figcaption {
	display: block;
}

p,
ul {
	margin-bottom: 1rem;
}

ul {
	list-style: none;
}

li {
	list-style-position: outside;
	//padding-left: 25px;
}

pre {
	font-family: monospace;
	font-size: 1rem;
	white-space: pre-line;
}

b,
strong {
	font-weight: bold;
}

a {
	text-decoration: none;
}

button,
input,
input[type="button"],
input[type="checkbox"],
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="radio"],
input[type="search"],
input[type="submit"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
	appearance: none;
	background-color: transparent;
	padding: .5rem .25rem;
	line-height: 1.25;
	box-shadow: none;
	border: 1px solid rgb(0, 0, 0);
	border-radius: 0;
	vertical-align: top;
	font-family: sans-serif;
	font-size: 100%;
}

button,
.button, input[type="submit"] {
	display: inline-block;
	background-color: transparent;
	border-radius: 0;
	padding: .5rem 1rem;

	&:focus {
        outline: none;
    }
}

a:focus {
    outline: none;
}

textarea {
	font-family: sans-serif;
	resize: vertical;
}

fieldset {
	border: none;
}

audio,
canvas,
iframe,
img,
svg,
video,
input,
select {
	vertical-align: middle;
}

img {
	max-width: 100%;
	height: auto;
}