/* =======================
   Modules
   ======================= */

/**
 *  Navigation
 */

.m-mainnav {
    
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: calc( #{$wrapper-width} + #{(2 * $general-margin)} );
        height: $nav-height;
        padding: 0 $general-margin;
        //@extend %wrapper;
        margin: 0 auto;  

        @media #{$medium, $small} {
            height: 6rem;
        }  

    }

    &__logo {
        display: flex;
        width: auto;
        height: 2.5rem;    
        background-repeat: no-repeat;
        background-size: contain;
        text-indent: -999rem;

        .swisswindow & {
            @media #{$small} {
                height: 2rem;
            }
        }

        img {
            display: block;   
            width: auto;
            max-width: none;
            height: 100%;
        }
    }

    /* Primary Nav Darwin */
    &__primary {

        // Desktop Nav
        @media #{$nav-desktop} {


            // General styling
            li {
                padding: 0;
                list-style: none;

                a {
                    display: block;
                }

                &.first {
                    display: none;
                }
            }

            $nav-padding: .7rem;

            // First level menu
        
            display: flex;
            margin-bottom: 0; // reset            
            margin-left: 1rem;

            > li {
                position: relative;
                margin: 0.2rem;
                line-height: $nav-height;

                &:hover {
                    > a {
                        background-color: $primary;
                        color: $white !important;
                    }

                    .sub-menu {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                > a {
                    padding: 0 $nav-padding;
                    color: $black;
                    font-weight: bold;
                    letter-spacing: 1.2px;
                    font-size: 1.4rem;
                    transition: background-color .3s ease, color .4s ease;
                    white-space: nowrap;
                }

                &.active > a {
                    color: $primary;
                }
            }

        }

        // Mobile Nav
        @media #{$nav-mobile} {
            display: none;

            .is-active & {
                //display: block;
                display: flex;
                flex-direction: column;
                flex-grow: 1;    
                z-index: 10;
                margin-bottom: 0;
            }

            position: fixed;
            top: $nav-height;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $primary;
            border-top: none;
            overflow: auto;

            @media #{$medium, $small} {
                top: 6rem;
            }  

            > ul > li > a {
                border-bottom: none;
                padding: 1rem 0;
                text-transform: uppercase;
                font-size: 1.3rem;
            }

            li {
                width: 100%;
                text-align: center;
                letter-spacing: 1.5px;
                font-weight: bold;

                > a {
                    //background-color: $neutral;
                    text-align: center;
                    padding: 1.1rem 0;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                    font-weight: bold;
                }

            }

            a {
                display: block;
                color: $white;
            }

            .current-menu-item > a {
                color: $black;
            }

            .menu-item-has-children {
                border-bottom: solid 2px darken($light, 5);

                > a {
                    border-bottom: none;
                }

                .is-subpage,
                &.current_page_parent > a {
                    background-color: $neutral;

                    + .sub-menu {
                        display: block;
                        background-color: $neutral;

                        a {
                            background-color: $neutral;
                        }
                    }
                }

                .sub-menu {
                    padding-bottom: .3rem;

                    a {
                        font-size: 1rem;
                        padding: .5rem 0;
                    }

                    .current-menu-item > a {
                        color: $primary;
                        background-color: $light;
                    }
                }

            }

            // Submenu
            .sub-menu {

                display: none;
                margin-bottom: 0;

            }
        }

    }


    /* Primary Nav Swiss Window */
    &__primary-sw {

        // Desktop Nav
        @media #{$nav-desktop} {

            // General styling
            li {
                padding: 0;
                list-style: none;

                a {
                    display: block;
                }
            }

            $nav-padding: .7rem;

            // First level menu
        
            display: flex;
            margin-bottom: 0; // reset

            > li {
                position: relative;
                margin: 0 .2rem;
                line-height: $nav-height;

                &:hover {
                    > a {
                        color: $white !important;
                    }

                    .sub-menu {
                        visibility: visible;
                        opacity: 1;
                        z-index: 20;

                        a {
                            &:hover {
                                color: rgba($white, .7);
                            }
                        }
                    }
                }

                > a {
                    padding: 0 $nav-padding;
                    color: $black;
                    font-weight: bold;
                    letter-spacing: 1.2px;
                    font-size: 1.2rem;
                    transition: background-color .3s ease, color .4s ease;
                    white-space: nowrap;
                }

                &.active > a {
                    color: $black;
                }
            }

            // Submenu
            .sub-menu {
                visibility: hidden;
                opacity: 0;
                @include transition(.5s);

                position: absolute;
                top: 100%;
                left: 0;
                background: inherit;
                padding: 1.5rem 0;
                z-index: 20;


                //box-shadow: 5px 5px 10px rgba($black, .15);

                li {
                    line-height: 1.6;
                    white-space: nowrap;
                    
                    &.current_page_item > a {
                        font-weight: bold;
                    }
                }

                a {
                    color: $white;
                    padding: 0.1rem 1.5rem;
                    letter-spacing: .7px;
                    transition: background-color .3s ease, color .4s ease;

                    &:hover {
                        color: rgba($white, .7);
                    }
                }
            }

            li:last-child .sub-menu {
                left: unset;
                right: 0;
            }

        }

        // Mobile Nav
        @media #{$nav-mobile} {
            display: none;

            .is-active & {
                //display: block;
                display: flex;
                flex-direction: column;
                flex-grow: 1;    
                z-index: 10;
                margin-bottom: 0;
            }

            position: fixed;
            top: $nav-height;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $white;
            border-top: none;
            overflow: auto;

            @media #{$medium, $small} {
                top: 6rem;
            }

            > ul > li > a {
                border-bottom: none;
                padding: 1rem 0;
                text-transform: uppercase;
                font-size: 1.3rem;
            }

            li {
                width: 100%;
                text-align: left;
                letter-spacing: 1.5px;
                font-weight: bold;

                > a {
                    //background-color: $neutral;
                    text-align: left;
                    padding: 1.1rem;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                    font-weight: bold;
                }

            }

            a {
                display: block;
                color: $black;

                &:hover {
                    color: rgba($red, .7);
                }
            }

            .current_page_item > a {
                color: $red;
            }

            .current_page_item {
                background-color: rgba($neutral, .7);

                .sub-menu {
                    display: block;
                    background-color: $neutral;

                    a {
                        background-color: $neutral;
                        font-size: 0.9rem;
                        font-weight: normal;
                    }
                }
            }

            .sub-menu {
                padding-bottom: .3rem;

                a {
                    color: $black;
                    font-size: 1rem;
                    padding: .5rem 1.5rem;

                    &:hover {
                        color: $red;
                    }
                }

                .current_page_item > a {
                    color: $red;
                    background-color: $neutral;
                }
            }

            // Submenu
            .sub-menu {

                display: none;
                margin-bottom: 0;

            }
        }

    }

}


/**
 *  Hamburger
 */
.m-hamburger {
    display: none;
    border: none;

    @media #{$nav-mobile} {
        display: block;
        position: absolute;
        top: 0.5rem;
        right: 0px;
        width: 5rem;
        height: 5rem;
        padding: 1.2rem;
        font-size: 40px;

        &__icon {
            height: 100%;
            width: 100%;
            background: {
                image: url( '../images/hamburger_darwin.svg' );
                size: contain;
                repeat: no-repeat;
                position: center;
            }

            .is-active & {
                height: 100%;
                width: 100%;
                margin: auto;
                background-image: url( '../images/close_darwin.svg' );
            }
        }
    }
}


/**
 *  Map
 */

.m-map {
    width: 100%;
    height: 0;
    padding-bottom: 20.5%;

    @media #{$small, $medium} {
        padding-bottom: 30rem;
    }

    @media #{$small} {
        padding-bottom: 80%;
    }

    /*
    &:last-of-type {
        position: fixed !important;
        height: 100%;
        padding-bottom: 0;
    }
     */
}

/*
    Main page Darwin
 */

.m-homegrid {
    display: flex;
    justify-content: space-between;

    @media #{$medium,$small} {
        flex-wrap: wrap;
    }

    &--row {
        flex-direction: row;
        justify-content: space-between;
    }

    // Item
    &__item {
        width: 24.7%;
        height: 20rem;
        padding: 0 1.5rem;
        background-color: $light;   

        &, a {
        
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;

        }

        a {
            color: $black;
        }

        h3 {
            margin-top: 1.5rem;
            font-size: 1.7rem;
            color: $black;
        }


        @media #{$medium} {
            width: 49.6%;
            margin-bottom: 0.4rem;
        }


        @media #{$small} {
            width: 100%;
            margin-bottom: 0.4rem;
        }
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10rem;
        height: 6.5rem;

        img {
        }
    }

}

/**
 * Contact
 */

.m-contact {
    display: flex;
    flex-direction: row;

    &__item {
        margin-right: 5%;
    }
}

/**
 * References
 */
.m-references {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__detail {
        flex-grow: 1;
        min-width: 33.3%;
        padding: 0 0.5rem 0.5rem 0;
        
        @media #{$large} {
            &:nth-child(3n) { 
                padding-right: 0;
            }
        }

        img {
            width: 100%;
            height: auto;
        }

        @media #{$medium} {
            width: 50%;
            margin-bottom: 0.4rem;
            
            &:nth-child(2n) { 
                padding-right: 0;
            }
        }

        @media #{$small} {
            width: 100%;
            margin-bottom: 0.4rem;
            
            &:nth-child(1n) { 
                padding-right: 0;
            }
        }
    }


}

/*
    Main page Swiss-Window
 */

 @media #{$nav-mobile} {
     .swisswindow {
    /**
     *  Hamburger
     */
        .m-hamburger {
            &__icon {
                background: {
                    image: url( '../images/hamburger_swisswindow.svg' );
                }
            }
        }

        .m-mainnav.is-active {
            .m-mainnav__inner {
                width: 100%;
                position: fixed;
            }
            .m-hamburger__icon {
                background-image: url( '../images/close_swisswindow.svg' );
            }
        }
            
        .l-header {
            .m-mainnav.is-active & {
                overflow: hidden; 
            }
        }

        &:not(.desktop) {

            .m-hamburger, .m-mainnav__logo {
                position: fixed;
            }
        }

    }
}

.m-swgrid {
    position: relative;
    display: flex;
    justify-content: space-between;

    @media #{$medium,$small} {
        flex-wrap: wrap;
    }

    &--row {
        flex-direction: row;
        justify-content: center;
    }

    // Item
    &__item {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;

        &:nth-child(4) {
            margin-right: 0;
        }

        a {
            color: $black;
        }

        h3 {
            margin-top: 1.5rem;
        }


        @media #{$medium} {
            width: 45%;
            margin-bottom: 0.4rem;

            &:nth-child(2) {
                margin-right: 0;
            }
        }


        @media #{$small} {
            width: 100%;
            margin-bottom: 0.4rem;
            margin-right: 0;

            &:nth-child(4) {
                margin-bottom: 0;
            }

            &:nth-child(4), &:nth-child(2) {

                img {
                    object-position: center;
                }
            }
        }


        /*@media #{$small} {
            width: 100%;
            margin-bottom: 0.4rem;
        }*/
    }

    &__image {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        cursor: pointer; 

        img {
            height: auto;

            @media #{$medium,$small} {
                width: 100%;
                height: 300px;
                object-fit: cover;
                object-position: bottom;
            }

            @media #{$small} {
                height: 200px;
            }
        }
        
    }

    &__title {
        position: absolute;
        top: 2rem; 
        width: 100%;
        font-size: 1.6rem;
        font-weight: bold;
        color: $black;

        @media #{$medium,$small} {
            font-size: 1.3rem;
        }
    }

    &__menu {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        @include custom(1200px) {
            padding: 0 2rem;
        }

        &-content {
            width: 100%;
            height: 100%;    
            padding: 0 5rem;
            opacity: 0.95;

            @media #{$medium} {
                padding: 0 3rem;
            }

            @media #{$small} {
                padding: 5rem 1rem 0 1rem;
            }

            h3 {
                display: block;
                width: 100%;
                padding: 1rem 0; 
                font-weight: normal;
                font-size: 2.5rem;
                text-align: center;
                color: $white;

                @media #{$small} {
                    font-size: 1.3rem;
                    text-align: left;
                }
            }
        }

        &-nav {

            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                list-style: none;

                li {
                    width: 33.3%;
                    text-align: center;

                    @media #{$medium} {
                        width: 50%;
                    }

                    @media #{$small} {
                        width: 100%;
                        text-align: left;
                    }

                    a {
                        color: $white;
                        font-size: 0.9rem;

                        &:hover {
                            color: rgba($white, .7);
                        }

                        @media #{$small} {
                            display: inline-block;
                            padding: 0.3rem 0;
                        }
                    }
                }
            }
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        width: 5rem;
        height: 5rem;
        background: {
            image: url('../images/close_white.svg');
            repeat: no-repeat;
            position: center;
            size: 50%;
        }
        z-index: 10;

        @include custom(1200px) {
            right: 2rem;
        }
    }

}

/**
 * Companies
 */
.m-companies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 3%;

    @include custom(1200px) {
        padding: 0 2rem;
    }

    &__detail {
        flex-grow: 0;
        min-width: 33.3%;
        padding: 0 0.5rem 0.2rem 0;
        
        @media #{$large} {
            &:nth-child(3n) { 
                padding-right: 0;
            }
        }

        @media #{$medium} {
            width: 50%;
            margin-bottom: 0.4rem;
            
            &:nth-child(2n) { 
                padding-right: 0;
            }
        }

        @media #{$small} {
            width: 100%;
            margin-bottom: 0.4rem;
            
            &:nth-child(1n) { 
                padding-right: 0;
            }
        }

        a {
            
            font-size: 0.8rem;
            color: $black;

            &:hover {
                color: $red;
            }
        }
    }


}

/**
 * canton select in references
 */
.m-cantonselect {
    width: 33.3%;
    margin: 0;
    color: rgb(145,146,157);
    background: {
        image: url(../images/arrow-down.svg);
        color: rgb(232,232,232);
        repeat: no-repeat;
        position: right 10px center;
        size: 6%;
    }

    @media #{$medium} {
        width: 50%;
        margin-bottom: 0.4rem;
        
        &:nth-child(2n) { 
            padding-right: 0;
        }
    }

    @media #{$small} {
        width: 100%;
        margin-bottom: 0.4rem;
        
        &:nth-child(1n) { 
            padding-right: 0;
        }
    }
}

/**
 * Banner
 */
.m-banner {
    &__big {
        text-align: center;
        padding-bottom: 2rem;

        a {
            display: block;

            img {
                width: 100%;
            }
        }
    }

    &__small {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;    
        justify-content: start;

        &-detail {
            width: 24%;
            margin-bottom: 1rem;
            text-align: center;
            padding: 1rem;
            background: none;

            @media #{$medium} {
                width: 30%;
            }

            @media #{$small} {
                width: 45%;
            }

            p {
                font-size: 0.8rem;
                margin-bottom: 0.2rem;
            }

            &:nth-child(4n) { 
            }

            a {
                display: block;
                font-size: 0.8rem;

                &.m-banner__imglink {
                    background: $white;
                }

                img {
                    width: 100%;
                    border: 1px solid $gray;
                }
            }

            h3 {    
                margin-top: 0.5rem;
                margin-bottom: 0rem;
                font-size: 0.8rem;
            }
        }
    }

    &__content, &__link {
        @media #{$small} {
            display: none !important;
        }
    }
}

/**
 * Search Form
 */
.m-search {
    &__form {
        background: $gray-sw;

        div {
            display: flex;
            flex-direction: row;
            max-width: calc( #{$wrapper-width} + #{(2 * $general-margin)} );
            padding: 0 $general-margin;
            margin: 0 auto;    

            input {
                width: 90%;
                margin: 0;
                color: rgb(145,146,157);

                &.m-search__button {
                    width: 10%; 
                    background-color: $gray-sw;
                    box-shadow: none;
                    text-indent: -9999px;
                    background: {
                        image: url( '../images/magnifier.svg' );
                        repeat: no-repeat;
                        position: center;
                        size: 1rem;
                    }
                }
            }
        }
    }
}


/**
 * SimpleSearch
 */
.sisea-results {
    font-size: 0.9rem;
    .sisea-highlight {
        color: $red;
        font-weight: bold;
    }
}
.sisea-paging {
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
    .sisea-result-pages{
        padding-right: 0.3rem;
    }
    .sisea-page {
        padding: 0 0.3rem;
        & .sisea-current-page {
            color: $red;
        }
    }
}
.sisea-results-list{
    font-size: 0.9rem;
    .sisea-result{
        h3 {
            font-size: 0.9rem;
        }
    }
}


.m-content {
    display: flex;
    flex-direction: column;
    max-width: calc( 1150px + 6%);
    padding: 2rem 3%;
    margin: 0 auto;
}

.m-servicenav-sw {

    border-bottom: 2px solid $lightgray-sw;

    &__inner {
        display: flex;
        flex-direction: column;
        max-width: calc( #{$wrapper-width} + 6%);
        padding: 1rem 3%;
        margin: 0 auto;

        ul {
            display: flex;
            justify-content: flex-end;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                margin-left: 1rem;

                a {
                    padding: 0 0.7rem;
                    color: $gray-sw;
                }
            }
        }
    }

    @media #{$medium, $small} {
        display: none;
        padding: 1rem 0;
        background-color: $white;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 9999;


        &__inner {

            ul {
                justify-content: flex-start;

                li {
                    margin-left: 0;
                    margin-right: 1rem;
                }
            }
        }

        .nav-is-active & {
            display: block;
        }
    }

}
