/* =======================
   Layouts
   ======================= */

/**
 *  Wrapper
 */

.l-wrapper {
    padding: 2rem 0;

    &.no-padding-bottom {
        padding-bottom: 0;
    }

    &.no-padding-top {
        padding-top: 0;
    }

    @media #{$small} {
    }

    &:first-child {
        padding-top: 0;
    }

    &__inner {
        @extend %wrapper;
        margin: 0 auto;
        /*padding: 0 $general-margin 2rem $general-margin;*/
        box-sizing: content-box;

        @include custom(1320px) {
            box-sizing: border-box;
            //padding: 2rem 0;
        }

        @include custom(1200px) {
            padding: 0 2rem;
        }

        /*&--title {

            padding: 2rem 0 0 0;

            @include custom(1320px) {
                padding: 2rem $general-margin;
            }

            ~ .l-wrapper__inner {

                padding-top: 0;

                &.m-masonry {
                    margin-top: 2rem;
                }

            }

        }*/
    }

    &__references {
        padding-top: 3rem;
    }
}


/**
 *  Main Container
 */

.l-main {
    display: none;

    body.desktop & {
        display: flex;
    }

    // Sticky footer
    flex: 1 0 auto;
    flex-direction: column;

    .l-wrapper:last-child {
        flex-grow: 1;
    }

    // Fullscreen map and gallery when just one item
    &[data-count="1"] {

        .l-wrapper .m-map,
        .l-wrapper .m-slider {
            position: fixed !important;
            height: 100%;
            padding-bottom: 0;
        }

        .m-slider--single .m-slider__item {
            height: 100%;
        }
    }
}

/**
 *  Footer
 */

.l-footer {
    
    display: none;

    body.desktop & {
        display: block;
    }

    padding: 2.5rem 0 0 0;
    background: $neutral;

    .l-wrapper {
        padding-bottom: 1rem;
    }
}


footer {

    background-color: $gray-footer;
    padding: 1rem;

    .m-footer__inner {
        max-width: calc( #{$wrapper-width} + 6%);
        margin: 0 auto;
        padding: 0 3%;

        ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-right: 0.5rem;

                a {
                    color: black;

                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }
}
